import React from "react";
import './snipp-drawer.css';
import { AiOutlineClose } from "react-icons/ai";

const SnippDrawer = (props) => {
    const { open, title, primaryAction, onPrimaryAction, secondaryAction, onSecondaryAction, width, onClose } = props;

    return open && <div className="snipp-drawer-backdrop">
        <div className={"snipp-flex-col snipp-drawer-slider" + (open ? ' open' : '')} style={width ? { width: width } : {}}>
            <div className="snipp-flex-row snipp-drawer-header mar-bot-20">
                <h1 className="snipp-flex-1">{title}</h1>
                <div className="snipp-drawer-close" onClick={onClose}>
                    <AiOutlineClose size={'1.5em'} />
                </div>
            </div>
            <div className="snipp-flex-1 snipp-drawer-body">
                {props.children}
            </div>
            <div className="snipp-drawer-footer">
                {secondaryAction && secondaryAction?.display && <button className="btn secondary" onClick={onSecondaryAction}>{secondaryAction?.label}</button>}
                &nbsp;&nbsp;
                {primaryAction && primaryAction?.display && <button className="btn" disabled={primaryAction?.display === 'DISABLED'} onClick={onPrimaryAction}>{primaryAction?.label}</button>}
            </div>
        </div>
    </div>

}

export default SnippDrawer;