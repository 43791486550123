import React, { useEffect, useState } from "react";
import './contact-requests.css';
import SnippLoading from "../common/components/snipp-loading/snipp-loading";

const ContactRequestsPage = (props) => {

    const [contactRequests, setContactRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (contactRequests?.length === 0) {

            fetch('/contact-requests')
                .then(res => res.json())
                .then(data => {
                    setContactRequests(data);
                    setIsLoading(false);
                });
        }
    }, [contactRequests]);

    return (
        <div className="content-container snipp-flex-col">
            <h1>Contact Requests</h1>
            <div className="snipp-flex-1 settings-tab-content">
                <div className="settings-const-table-container">
                    {isLoading ? <SnippLoading />
                        :
                        <>
                            <div className="snipp-flex-row flex-justify-space-between">
                                <div>{contactRequests?.length} Record(s) Found</div>
                            </div>
                            <br />
                            <table width="100%" cellPadding={0} cellSpacing={0} border={0} className="settings-const-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Subject</th>
                                        <th>Message</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contactRequests.map((c, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{c.requestorName}</td>
                                                <td>{c.requestorEmail}</td>
                                                <td>{c.reqSub}</td>
                                                <td>{c.reqMsg}</td>
                                                <td>{c.reqDate}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </>}
                </div>
            </div>
        </div>
    );
}

export default ContactRequestsPage;