import React, { useState, useCallback } from "react";
import PostContentTab from "../content-tab/post-content-tab";
import TabBar from "../../common/components/tab-bar/snipp-tab-bar";
import './post-viewer.css';
import PostSeoTab from "../seo-tab/post-seo-tab";

const PostViewer = (props) => {
    const { tool, onEditStart } = props;
    const [currentActiveTab, setCurrentActiveTab] = useState(0);

    const handleTabSelection = useCallback((num) => {
        setCurrentActiveTab(num);
    }, []);

    return (
        <div className='right-content-container'>
            <div>
                <div className='snipp-flex-row flex-justify-space-between'>
                    <div>
                        <h2 className="post-viewer-title">{tool?.postTitle}</h2>
                    </div>
                    <div>
                        <button onClick={onEditStart} className='btn'>Edit</button>
                    </div>
                </div>
                <label>Slug</label>
                {/* <input type='text' value={tool?.postUrl} onChange={handleUrlChange}/> */}
                https://snipp-it.com/ {
                    tool?.toolCategoryUrl && <span>
                        <div className={!tool.postUrl ? 'tag' : 'tag-grey'}>{tool?.toolCategoryUrl}</div> / {tool.postUrl && <><div className='tag'>{(tool?.postUrl ? tool.postUrl : '')}</div> /</>}
                    </span>
                }
                <br /><br />
            </div>
            <TabBar currentTab={currentActiveTab} tabs={['Content', 'SEO', 'Indexing']} onTabClick={handleTabSelection}></TabBar>
            <div className='snipp-flex-1 right-content'>
                {currentActiveTab === 0 && <PostContentTab post={tool} editMode={false} />}
                {currentActiveTab === 1 && <PostSeoTab post={tool} editMode={false} />}
            </div>
        </div>
    );
}

export default PostViewer;