import React from "react";

import './dashboard.css';

const DashboardPage = (props) => {

    return (
        <div className="content-container">
            <h1>Dashboard</h1>
        </div>
    );
}

export default DashboardPage;