import React, { Fragment, useCallback, useEffect, useState, useRef } from "react";
import './post-seo-tab.css';

const PostSeoTab = (props) => {
    const { post, editMode, onContentUpdate } = props;
    const { postUrl, toolCategoryUrl, seoDetails } = post;
    const [sameAsFlags, setSameAsFlags] = useState({
        openGraphDetails: {
            title: false, description: false
        },
        twitterDetails: {
            title: false, description: false
        }
    });
    const didMount = useRef(false);

    const [seoData, setSeoData] = useState(seoDetails || {
        metaDetails: {},
        openGraphDetails: {},
        twitterDetails: {},
    });

    const handleSeoDataChange = useCallback((e, subObj, pr) => {
        const val = e.target?.value;
        setSeoData(prevData => {
            const newData = { ...prevData };
            newData[subObj][pr] = val;
            return newData;
        });
        if (subObj === 'metaDetails') {
            if (pr === 'metaTitle') {
                if (sameAsFlags?.openGraphDetails?.title) {
                    handleSeoDataChange(e, 'openGraphDetails', 'title');
                }
                if (sameAsFlags?.twitterDetails?.title) {
                    handleSeoDataChange(e, 'twitterDetails', 'title');
                }
            } else if (pr === 'metaDesc') {
                if (sameAsFlags?.openGraphDetails?.description) {
                    handleSeoDataChange(e, 'openGraphDetails', 'description');
                }
                if (sameAsFlags?.twitterDetails?.description) {
                    handleSeoDataChange(e, 'twitterDetails', 'description');
                }
            }
        }
    }, [sameAsFlags]);

    const handleSameAsCheck = useCallback((e, subObj, pr) => {
        const checked = e.target?.checked;
        setSameAsFlags(prev => {
            const newFlags = { ...prev };
            newFlags[subObj][pr] = checked;
            return newFlags;
        })
        const val = checked ? seoData?.metaDetails[pr === 'title' ? 'metaTitle' : 'metaDesc'] : seoDetails[subObj][pr];
        handleSeoDataChange({ target: { value: val } }, subObj, pr);
    }, [handleSeoDataChange, seoData, seoDetails]);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }
        onContentUpdate(seoData);
    }, [onContentUpdate, seoData]);

    return (
        // <Fragment>
        //     {!editMode && <div>
        //         SEO Date
        //     </div>}
        //     {editMode &&
        <Fragment>
            <div className="snipp-flex-row mar-bot-40">
                <div className="snipp-flex-1">
                    <label>Meta Tags</label>
                    <div className="sub-text">
                        Enter a meta title
                    </div>
                </div>
                <div className="snipp-flex-3 right-side-form">
                    <label>Title</label>
                    <textarea rows="3" className="snipp-textarea"
                        disabled={!editMode}
                        onChange={(e) => handleSeoDataChange(e, 'metaDetails', 'metaTitle')}
                        defaultValue={seoDetails?.metaDetails?.metaTitle}
                        value={seoDetails?.metaDetails?.metaTitle || ''}></textarea>
                    <br /><br />
                    <label>Description</label>
                    <textarea rows="3" className="snipp-textarea"
                        disabled={!editMode}
                        onChange={(e) => handleSeoDataChange(e, 'metaDetails', 'metaDesc')}
                        defaultValue={seoDetails?.metaDetails?.metaDesc || ''}></textarea>
                    <br /><br />
                    <label>Keywords</label>
                    <textarea rows="3" className="snipp-textarea"
                        disabled={!editMode}
                        onChange={(e) => handleSeoDataChange(e, 'metaDetails', 'metaKeywords')}
                        defaultValue={seoDetails?.metaDetails?.metaKeywords || ''}></textarea>
                    <br /><br />
                    <label>Canonical URL</label>
                    <input type="text" className="full-width"
                        disabled={!editMode}
                        onChange={(e) => handleSeoDataChange(e, 'metaDetails', 'canonicalUrl')}
                        defaultValue={seoDetails?.metaDetails?.canonicalUrl || ('/' + toolCategoryUrl + '/' + postUrl + '/')} />
                    <br /><br />
                </div>
            </div>
            <div className="snipp-flex-row mar-bot-40">
                <div className="snipp-flex-1">
                    <label>Open Graph Tags</label>
                    <div className="sub-text">
                        Enter a meta title
                    </div>
                </div>
                <div className="snipp-flex-3 right-side-form">
                    <div className="snipp-flex-row flex-justify-space-between">
                        <label className="snipp-flex-1">Title</label>
                        <div className="same-as-block">
                            <input type="checkbox" disabled={!editMode}
                                onChange={(e) => handleSameAsCheck(e, 'openGraphDetails', 'title')} />
                            <label className="inline-label">Same as Meta Title</label>
                        </div>
                    </div>
                    <textarea rows="3" className="snipp-textarea"
                        disabled={sameAsFlags?.openGraphDetails?.title || !editMode}
                        onChange={(e) => handleSeoDataChange(e, 'openGraphDetails', 'title')}
                        defaultValue={seoDetails?.openGraphDetails?.title}
                        value={seoDetails?.openGraphDetails?.title || ''}
                        v                                ></textarea>
                    <br /><br />
                    <div className="snipp-flex-row flex-justify-space-between">
                        <label className="snipp-flex-1">Description</label>
                        <div className="same-as-block">
                            <input type="checkbox" disabled={!editMode}
                                onChange={(e) => handleSameAsCheck(e, 'openGraphDetails', 'description')} />
                            <label className="inline-label">Same as Meta Description</label>
                        </div>
                    </div>
                    <textarea rows="3" className="snipp-textarea"
                        disabled={sameAsFlags?.openGraphDetails?.description || !editMode}
                        onChange={(e) => handleSeoDataChange(e, 'openGraphDetails', 'description')}
                        defaultValue={seoDetails?.openGraphDetails?.description}
                        value={seoDetails?.openGraphDetails?.description || ''}></textarea>
                    <br /><br />
                    <div className="snipp-flex-row">
                        <div className="snipp-flex-1">
                            <label>OG:Site Name</label>
                            <input type="text" className="full-width"
                                disabled={!editMode}
                                onChange={(e) => handleSeoDataChange(e, 'openGraphDetails', 'siteName')}
                                defaultValue={seoDetails?.openGraphDetails?.siteName || 'Snipp-It'} />
                        </div>
                        <div className="space-divider"></div>
                        <div className="snipp-flex-1">
                            <label>OG:Type</label>
                            <input type="text" className="full-width"
                                disabled={!editMode}
                                onChange={(e) => handleSeoDataChange(e, 'openGraphDetails', 'type')}
                                defaultValue={seoDetails?.openGraphDetails?.type || 'article'} />
                        </div>
                    </div>
                    <br /><br />
                    <div className="snipp-flex-row">
                        <div className="snipp-flex-1">
                            <label>OG:URL</label>
                            <input type="text" className="full-width"
                                disabled={!editMode}
                                onChange={(e) => handleSeoDataChange(e, 'openGraphDetails', 'url')}
                                defaultValue={seoDetails?.openGraphDetails?.url || 'https://snipp-it.com/' + toolCategoryUrl + '/' + postUrl + '/'} />
                        </div>
                        <div className="space-divider"></div>
                        <div className="snipp-flex-1">
                            <label>OG:Image URL</label>
                            <input type="text" className="full-width"
                                disabled={!editMode}
                                onChange={(e) => handleSeoDataChange(e, 'openGraphDetails', 'image')}
                                defaultValue={seoDetails?.openGraphDetails?.image} />
                        </div>
                    </div>
                    <br /><br />
                </div>
            </div>
            <div className="snipp-flex-row">
                <div className="snipp-flex-1">
                    <label>Twitter Tags</label>
                    <div className="sub-text">
                        Enter a meta title
                    </div>
                </div>
                <div className="snipp-flex-3 right-side-form">
                    <div className="snipp-flex-row flex-justify-space-between">
                        <label className="snipp-flex-1">Title</label>
                        <div className="same-as-block">
                            <input type="checkbox"
                                disabled={!editMode}
                                onChange={(e) => handleSameAsCheck(e, 'twitterDetails', 'title')} />
                            <label className="inline-label">Same as Meta Title</label>
                        </div>
                    </div>
                    <textarea rows="3" className="snipp-textarea"
                        disabled={!editMode || (seoData?.metaDetails?.metaTitle && seoData?.metaDetails?.metaTitle === seoData?.twitterDetails?.title)}
                        onChange={(e) => handleSeoDataChange(e, 'twitterDetails', 'title')}
                        value={seoDetails?.twitterDetails?.title}
                        defaultValue={seoDetails?.twitterDetails?.title || ''}></textarea>
                    <br /><br />
                    <div className="snipp-flex-row flex-justify-space-between">
                        <label className="snipp-flex-1">Description</label>
                        <div className="same-as-block">
                            <input type="checkbox"
                                disabled={!editMode}
                                onChange={(e) => handleSameAsCheck(e, 'twitterDetails', 'description')} />
                            <label className="inline-label">Same as Meta Description</label>
                        </div>
                    </div>
                    <textarea rows="3" className="snipp-textarea"
                        disabled={!editMode || (seoData?.metaDetails?.metaDesc && seoData?.metaDetails?.metaDesc === seoData?.twitterDetails?.description)}
                        onChange={(e) => handleSeoDataChange(e, 'twitterDetails', 'description')}
                        value={seoDetails?.twitterDetails?.description}
                        defaultValue={seoDetails?.twitterDetails?.description || ''}></textarea>
                    <br /><br />
                    <div className="snipp-flex-row">
                        <div className="snipp-flex-1">
                            <label>Twitter:Card</label>
                            <input type="text" className="full-width"
                                disabled={!editMode}
                                onChange={(e) => handleSeoDataChange(e, 'twitterDetails', 'card')}
                                defaultValue={seoDetails?.twitterDetails?.card || 'summary_large_image'} />
                        </div>
                        <div className="space-divider"></div>
                        <div className="snipp-flex-1">
                            <label>Twitter:Image</label>
                            <input type="text" className="full-width"
                                disabled={!editMode}
                                onChange={(e) => handleSeoDataChange(e, 'twitterDetails', 'image')}
                                defaultValue={seoDetails?.twitterDetails?.image} />
                        </div>
                    </div>
                    <br /><br />
                    <div className="snipp-flex-row">
                        <div className="snipp-flex-1">
                            <label>Twitter:Site</label>
                            <input type="text" className="full-width"
                                disabled={!editMode}
                                onChange={(e) => handleSeoDataChange(e, 'twitterDetails', 'site')}
                                defaultValue={seoDetails?.twitterDetails?.site} />
                        </div>
                        <div className="space-divider"></div>
                        <div className="snipp-flex-1">
                            <label>Twitter:Creator</label>
                            <input type="text" className="full-width"
                                disabled={!editMode}
                                onChange={(e) => handleSeoDataChange(e, 'twitterDetails', 'creator')}
                                defaultValue={seoDetails?.twitterDetails?.creator} />
                        </div>
                    </div>
                    <br /><br />
                </div>
            </div>
        </Fragment>
        //     }
        // </Fragment>
    );
}

export default PostSeoTab;