import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editor.css';

const EditorComponent = (props) => {
    const { content, onContentUpdate } = props;

    const [editorState, setEditorState] = useState(EditorState.createWithContent(stateFromHTML(content || '<h3>Sample Title</h3>')));

    const onEditorStateChange = useCallback((text) => {
        setEditorState(text);
        // onContentUpdate(convertToHTML(editorState.getCurrentContent()));
    }, []);

    useEffect(() => {
        onContentUpdate?.(convertToHTML(editorState.getCurrentContent()));
    }, [onContentUpdate, editorState]);

    return (
        <Editor
            editorState={editorState}
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
        />
    );
}
export default EditorComponent;