import React from "react";
import loadingImg from './../../../assets/Loading-rafiki.svg';

const SnippLoading = (props) => {
    return <div className='no-tool-selected'>
        <div>
            <img src={loadingImg} alt="Loading"
                width="150px" height="150px"></img>
            <br />
            Loading...
        </div>
    </div>;
}

export default SnippLoading;