import React, { useState, useEffect, useCallback, Fragment, useMemo } from 'react';
import './post-list.css';
import PostEditor from '../post-editor/post-editor';
import PostViewer from '../post-viewer/post-viewer';
import noDataImg from './../../assets/Gaming-amico.svg';
import TabBar from '../../common/components/tab-bar/snipp-tab-bar';
import SnippDrawer from '../../common/components/snipp-drawer/snipp-drawer';
import SnippDialog from '../../common/components/snipp-dialog/snipp-dialog';
import SnippLoading from '../../common/components/snipp-loading/snipp-loading';

const PostList = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [allTools, setAllTools] = useState({});
    const [activeTool, setActiveTool] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [currentActiveTab, setCurrentActiveTab] = useState(1);
    const [currentActiveSection, setCurrentActiveSection] = useState(-1);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [newPageData, setNewPageData] = useState({});
    const [discardDialogOpen, setDiscardDialogOpen] = useState(false);

    const handleTabSelection = useCallback((num) => {
        setCurrentActiveTab(num);
    }, []);

    const fetchAllTools = useCallback(() => {
        fetch('/all-tools').then(res => res.json()).then(data => {
            setAllTools(data);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchAllTools();
    }, [fetchAllTools]);

    const handleOpenToolDetails = useCallback((e, tool) => {
        e.preventDefault();
        if (editMode) {
            setDiscardDialogOpen(true);
        } else {
            fetch('/tool/content/' + tool?.id)
                .then(res => res.json())
                .then(data => {
                    setActiveTool(data);
                });
        }
    }, [editMode]);

    const handleOpenToolSection = useCallback((e, tool, num) => {
        setCurrentActiveSection(num);
        handleOpenToolDetails(e, tool);
    }, [handleOpenToolDetails]);

    const renderList = useMemo(() => {
        let pageList = Object.keys(allTools)?.filter(key => currentActiveTab === 1 ? key !== "legal" : key === "legal")
        if (currentActiveTab === 0) {
            return (
                <ul>
                    {allTools["legal"]?.map((tool, j) => {
                        return (
                            <li key={j}>
                                <a href={'/'} className={activeTool?.postUrl === tool?.postUrl && activeTool?.toolCategoryUrl === tool?.toolCategoryUrl ? 'active-tool' : ''}
                                    onClick={(e) => handleOpenToolDetails(e, tool)}>{!tool.postUrl && !tool.toolCategoryUrl ? 'Home Page' : tool?.postTitle}</a>
                            </li>

                        );
                    })}
                </ul>
            );
        } else if (currentActiveTab === 1) {
            return (
                <>
                    {pageList?.map((key, i) => {
                        return (
                            <Fragment key={i}>
                                <div onClick={(e) => handleOpenToolSection(e, allTools[key], i)}
                                    className={'left-nav-list-section ' + (currentActiveSection === i ? 'active-section' : '')}>{key}</div>
                                {currentActiveSection === i && <ul>
                                    {allTools[key]["tools"]?.map((tool, j) => {
                                        return (
                                            <li key={j}>
                                                <a href={'/'} className={activeTool?.postUrl === tool?.postUrl && activeTool?.toolCategoryUrl === tool?.toolCategoryUrl ? 'active-tool' : ''}
                                                    onClick={(e) => handleOpenToolDetails(e, tool)}>{tool?.postTitle}</a>
                                            </li>

                                        );
                                    })}
                                </ul>}
                            </Fragment>
                        );
                    })}
                </>
            );
        }
    }, [currentActiveTab, activeTool, currentActiveSection, allTools, handleOpenToolSection, handleOpenToolDetails]);

    const handleDrawerOpen = useCallback(() => {
        if (editMode) {
            setDiscardDialogOpen(true);
        } else {
            setDrawerOpen(true);
        }
    }, [editMode]);

    const handleDrawerClose = useCallback(() => {
        setNewPageData({});
        setDrawerOpen(false);
    }, []);

    const handleDrawerPrimaryAction = useCallback(() => {
        fetch('/page', {
            method: 'POST',
            body: JSON.stringify(newPageData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data?.status) {
                    handleDrawerClose();
                    fetchAllTools();
                }
            });
    }, [newPageData, fetchAllTools, handleDrawerClose]);

    const handleDrawerSecondaryAction = useCallback(() => {
        handleDrawerClose();
    }, [handleDrawerClose]);


    const handleTitleChange = useCallback((e) => {
        setNewPageData((prevData) => {
            return { ...prevData, postTitle: e.target?.value }
        });
    }, []);

    const handleCategoryUrlChange = useCallback((e) => {
        setNewPageData((prevData) => {
            return { ...prevData, toolCategoryUrl: e.target?.value }
        });
    }, []);

    const handleUrlChange = useCallback((e) => {
        setNewPageData((prevData) => {
            return { ...prevData, postUrl: e.target?.value }
        });
    }, []);

    const handleDialogClose = useCallback(() => {
        setDiscardDialogOpen(false);
    }, []);

    const handleDialogPrimaryAction = useCallback(() => {
        handleDialogClose(false);
    }, [handleDialogClose]);

    return (
        <div className='snipp-flex-col post-list-page'>
            <h1 className='post-list-page-h1'>All Tools</h1>
            <div className='snipp-flex-row post-container snipp-flex-1'>
                <div className='LeftNav snipp-flex-col'>
                    <div className='left-nav-tabs'>
                        <TabBar currentTab={currentActiveTab}
                            onTabClick={handleTabSelection}
                            tabs={['General Pages', 'Tool Pages']} />
                    </div>
                    <div className='snipp-flex-1 left-nav-list'>
                        <div className='left-nav-list-btn' onClick={handleDrawerOpen}>Add a new {currentActiveTab === 1 && 'Tool'} Page</div>
                        {allTools && renderList}
                    </div>
                </div>
                <div className='RightPart'>
                    {!activeTool &&
                        <div className='no-tool-selected'>
                            {isLoading && <SnippLoading />
                            }
                            {!isLoading && <div>
                                <img src={noDataImg} alt="nodata"
                                    width="150px" height="150px"></img>
                                <br />
                                Select a tool to view/edit
                            </div>
                            }
                        </div>
                    }
                    {activeTool &&
                        !editMode &&
                        <PostViewer tool={activeTool} onEditStart={() => setEditMode(true)} />
                    }
                    {activeTool &&
                        editMode &&
                        <PostEditor tool={activeTool} onCancel={setEditMode} onSave={setActiveTool} />
                    }
                </div>
            </div>
            <SnippDrawer open={drawerOpen} title={'Add a New Page'} onClose={handleDrawerClose}
                primaryAction={{ label: 'Save', display: newPageData?.postTitle ? 'ON' : 'DISABLED' }}
                onPrimaryAction={handleDrawerPrimaryAction}
                secondaryAction={{ label: 'Cancel', display: 'ON' }}
                onSecondaryAction={handleDrawerSecondaryAction}>
                <div><label>Title</label>
                    <input type='text' defaultValue={newPageData?.postTitle}
                        className='long' onChange={handleTitleChange} />
                    <br /><br />
                    <label>Category</label>
                    <input type='text' defaultValue={newPageData?.toolCategoryUrl}
                        onChange={handleCategoryUrlChange} />
                    <div className='sub-label'>Category URL must end with '-tools' if this is a tool page.</div>
                    <br /><br />
                    <label>Slug</label>
                    <input type='text' defaultValue={newPageData?.postUrl}
                        onChange={handleUrlChange} />
                    <div className='sub-label'>Make sure the category exists, else create the category pae first.</div>
                </div>
            </SnippDrawer>
            <SnippDialog title={'Edit already active'}
                isOpen={discardDialogOpen} onClose={handleDialogClose}
                primaryAction={{ label: 'Close', display: 'ON' }}
                onPrimaryAction={handleDialogPrimaryAction}
            >
                Currently edit is open for another page. Save/close that first to open a new page.
            </SnippDialog>
        </div>
    );
}

export default PostList;
