import React, { useCallback, useEffect, useRef, useState } from "react";
import './snipp-dialog.css';
import { AiOutlineClose } from "react-icons/ai";

const SnippDialog = (props) => {
    const { isOpen, title, onClose, primaryAction, onPrimaryAction, secondaryAction, onSecondaryAction, children } = props;

    const [isModalOpen, setModalOpen] = useState(isOpen);
    const modalRef = useRef(null);

    const handleCloseModal = useCallback(() => {
        if (onClose) {
            onClose();
        }
        setModalOpen(false);
    }, [onClose]);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);


    useEffect(() => {
        const modalElement = modalRef.current;

        if (modalElement) {
            if (isModalOpen) {
                modalElement.showModal();
            } else {
                modalElement.close();
            }
        }
    }, [isModalOpen]);

    return (
        <dialog ref={modalRef} className="snipp-dialog">
            <div className="snipp-flex-col">
                <div className="snipp-flex-row mar-bot-20">
                    <div className="snipp-flex-1 snipp-dialog-title"><b>{title}</b></div>
                    <div className="snipp-drawer-close" onClick={handleCloseModal}>
                        <AiOutlineClose size={'1.5em'} />
                    </div>
                </div>
                <div className="mar-bot-40">
                    {children}
                </div>
                <div className="snipp-flex-row snipp-dialog-footer">
                    {secondaryAction && secondaryAction?.display && <button className="btn secondary" onClick={onSecondaryAction}>{secondaryAction?.label}</button>}
                    &nbsp;&nbsp;
                    {primaryAction && primaryAction?.display && <button className="btn" disabled={primaryAction?.display === 'DISABLED'} onClick={onPrimaryAction}>{primaryAction?.label}</button>}
                </div>
            </div>
        </dialog>
    );
}

export default SnippDialog;
