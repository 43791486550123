import React, { useCallback, useEffect, useMemo, useState } from "react";

import './settings.css';
import TabBar from "../common/components/tab-bar/snipp-tab-bar";
import AddEditConstants from "./add-edit-constants/add-edit-constants";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import useDeleteDialog from "../common/hooks/use-delete-dialog/use-delete-dialog";
import SnippLoading from "../common/components/snipp-loading/snipp-loading";

const SettingsPage = (props) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [allTools, setAllTools] = useState(null);
    const [selectedConstant, setSelectedConstant] = useState({});
    const [constantsData, setConstantsData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [showDeleteDialog, hideDeleteDialog, deleteDialogRendered, deleteDialogResult] = useDeleteDialog();

    const handlePageSelect = useCallback((tool) => {
        tool.checked = !tool.checked;
        setAllTools([...allTools]);
    }, [allTools]);

    const fetchAllConstants = useCallback(() => {
        setIsLoading(true);
        fetch('/constants').then(res => res.json()).then(data => {
            setConstantsData(data);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (activeTab > -1) {
            if (activeTab === 1) {
                fetchAllConstants();
            }
        }
    }, [activeTab, fetchAllConstants]);

    const fetchAllPages = useCallback(() => {
        setIsLoading(true);
        fetch('/all-tools').then(res => res.json()).then(data => {
            let keys = Object.keys(data)?.filter(key => key !== "legal");
            let arr = [];
            keys.forEach(key => {
                data[key]["checked"] = true;
                data[key]["tools"].forEach(t => {
                    t["checked"] = true;
                })
                arr.push(data[key]);
            })
            setAllTools(arr);
            setIsLoading(false);
        });
    }, []);

    const totalSelectedPages = useMemo(() => {
        let count = 2;
        if (allTools) {
            allTools.forEach(tool => {
                if (tool?.checked) {
                    count++;
                }
                tool.tools.forEach(st => {
                    if (st?.checked) {
                        count++;
                    }
                })
            });
        }
        return count;
    }, [allTools]);

    const downloadSitemap = useCallback(() => {
        let xmlText = `<?xml version="1.0" encoding="UTF-8"?>
  <urlset
        xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
              http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">`;
        let baseUrl = 'https://snipp-it.com/';
        let date = new Date();
        let dateStr = date.toISOString().substring(0, 19);
        xmlText += `<url>
        <loc>${baseUrl}</loc>
        <lastmod>${dateStr}+00:00</lastmod>
        <priority>${'1.00'}</priority>
      </url><url>
      <loc>${baseUrl}all-free-tools/</loc>
      <lastmod>${dateStr}+00:00</lastmod>
      <priority>${'0.80'}</priority>
    </url>`;
        allTools.forEach(section => {
            if (section?.checked) {
                xmlText += `<url>
            <loc>${baseUrl}${section?.toolCategoryUrl}/</loc>
            <lastmod>${dateStr}+00:00</lastmod>
            <priority>${'0.80'}</priority>
          </url>`;
            }
            section?.tools?.forEach(tool => {
                if (tool?.checked) {
                    xmlText += `<url>
            <loc>${baseUrl}${tool?.toolCategoryUrl}/${tool?.postUrl}/</loc>
            <lastmod>${dateStr}+00:00</lastmod>
            <priority>${'0.80'}</priority>
          </url>`;
                }
            });
        });

        xmlText += '</urlset>';
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(xmlText));
        element.setAttribute('download', 'sitemap.xml');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }, [allTools]);

    const sitemapPage = useMemo(() => {
        return (isLoading ?
            <SnippLoading />
            :
            <div>
                Generate sitemap.xml file.
                <br /><br />
                <div className="mar-bot-40 snipp-flex-row">
                    <button onClick={fetchAllPages} className="btn">Show All Pages</button>
                    {allTools && <button onClick={downloadSitemap} className="btn mar-left-20">Download ({totalSelectedPages})</button>}
                </div>
                {allTools && <>
                    <div className="snipp-flex-row">
                        <input type="checkbox" checked disabled id={'homePage'} />
                        <label htmlFor={'homePage'}>Home Page</label>
                    </div>
                    <div className="snipp-flex-row">
                        <input type="checkbox" checked disabled id={'allFreeTools'} />
                        <label htmlFor={'allFreeTools'}>All Free Tools</label>
                    </div>
                </>}
                {allTools && allTools?.map((tool, i) => {
                    return (
                        <div key={'section_' + i} className="mar-left-20">
                            <div className="snipp-flex-row">
                                <input type="checkbox" id={'section_' + i} checked={tool?.checked} onChange={(e) => handlePageSelect(tool)} />
                                <label htmlFor={'section_' + i}>{tool?.toolCategoryUrl} ({tool?.checked ? '1 + ' : ''}{tool?.tools?.length} Pages)</label>
                            </div>
                            <div className="mar-left-20 mar-bot-20">
                                {tool?.tools?.map((st, j) => {
                                    return (
                                        <div className="snipp-flex-row" key={'section_' + i + '_tool_' + j}>
                                            <input type="checkbox" id={'section_' + i + '_tool_' + j} checked={st?.checked} onChange={(e) => handlePageSelect(st)} />
                                            <label htmlFor={'section_' + i}>{st?.postTitle}</label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }, [isLoading, allTools, fetchAllPages, handlePageSelect, downloadSitemap, totalSelectedPages]);

    const onAddConstant = useCallback(() => {
        setDrawerOpen(true);
    }, []);

    const handleConstantsEdit = useCallback((c) => {
        setSelectedConstant(JSON.parse(JSON.stringify(c)));
        setDrawerOpen(true);
    }, []);

    const handleConstantDelete = useCallback((c) => {
        setSelectedConstant(JSON.parse(JSON.stringify(c)));
        showDeleteDialog();
    }, [showDeleteDialog]);

    const deleteConstant = useCallback(() => {
        if (selectedConstant?.id) {
            setSelectedConstant({});
            // todo: delete api
            fetch("/constants/" + selectedConstant?.id, {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    hideDeleteDialog();
                    fetchAllConstants();
                });
        }
    }, [hideDeleteDialog, fetchAllConstants, selectedConstant]);

    useEffect(() => {
        if (deleteDialogResult === 'DELETE') {
            deleteConstant();
        }
    }, [deleteDialogResult, deleteConstant]);

    const handleDrawerClose = useCallback(() => {
        setSelectedConstant({});
        setDrawerOpen(false);
        fetchAllConstants();
    }, [fetchAllConstants]);

    const handleConstantsSave = useCallback((newVal) => {
        setSelectedConstant(JSON.parse(JSON.stringify(newVal)));
    }, []);

    const constantsTable = useMemo(() => {
        return (isLoading || !constantsData ?
            <SnippLoading />
            :
            <div className="settings-const-table-container">
                <div className="snipp-flex-row flex-justify-space-between">
                    <div>{constantsData?.length} Record(s) Found</div>
                    <div>
                        <button onClick={onAddConstant} className='btn'>Add</button>
                    </div>
                </div>
                <br />
                <table width="100%" cellPadding={0} cellSpacing={0} border={0} className="settings-const-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Values</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {constantsData?.map((c, i) => {
                            return (
                                <tr key={i} onDoubleClick={(e) => handleConstantsEdit(c)}>
                                    <td>{c.name}</td>
                                    <td>{c.category}</td>
                                    <td>{c.subCategory}</td>
                                    <td>{c.values?.length}</td>
                                    <td>
                                        <div className="snipp-flex-row flex-justify-space-between">
                                            <AiOutlineEdit title="Edit" className="snipp-flex-1" onClick={() => handleConstantsEdit(c)} />
                                            <AiOutlineDelete title="Delete" className="snipp-flex-1" onClick={() => handleConstantDelete(c, i)} />
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }, [isLoading, constantsData, onAddConstant, handleConstantsEdit, handleConstantDelete]);

    return (
        <div className="content-container snipp-flex-col">
            <h1>Settings</h1>
            <div className="snipp-flex-1 snipp-flex-col settings-tab-content-container">
                <TabBar tabs={['Sitemap.xml', 'Constants']} onTabClick={setActiveTab} currentTab={activeTab} />
                <div className="snipp-flex-1 settings-tab-content">
                    {activeTab === 0 && sitemapPage}
                    {activeTab === 1 && constantsTable}
                </div>
            </div>
            <div>&nbsp;</div>
            <AddEditConstants isOpen={drawerOpen}
                onClose={handleDrawerClose}
                onSave={handleConstantsSave}
                selectedConstant={selectedConstant} />
            {deleteDialogRendered}
        </div>
    );
}

export default SettingsPage;