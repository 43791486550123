import React, { useEffect, useState } from "react";
import './snipp-tab-bar.css';

const TabBar = (props) => {
    const { currentTab, tabs, onTabClick } = props;

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setActiveTab(currentTab);
    }, [currentTab]);

    return (
        <div className="snipp-tab-bar">
            <ul>
                {tabs?.map((tab, i) => <li key={i}
                    className={activeTab === i ? "active-tab" : ''} onClick={() => onTabClick(i)}>
                    {tab}
                </li>)}
            </ul>
        </div>
    );
}

export default TabBar;