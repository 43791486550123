import React, { useState, useCallback, useMemo } from 'react';
import './post-editor.css';
import PostContentTab from '../content-tab/post-content-tab';
import TabBar from '../../common/components/tab-bar/snipp-tab-bar';
import PostSeoTab from '../seo-tab/post-seo-tab';
import SnippDialog from '../../common/components/snipp-dialog/snipp-dialog';

const PostEditor = (props) => {
    const { tool, onCancel, onSave } = props;
    const [currentTool, setCurrentTool] = useState(JSON.parse(JSON.stringify(tool)));
    const [currentActiveTab, setCurrentActiveTab] = useState(0);
    const [isSaved, setIsSaved] = useState(false);
    const [discardDialogOpen, setDiscardDialogOpen] = useState(false);

    const handleTabSelection = useCallback((num) => {
        setCurrentActiveTab(num);
    }, []);

    const handleEditSave = useCallback(() => {
        fetch(`/tool/content/${tool?.id}`, {
            method: 'PUT',
            body: JSON.stringify(currentTool),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                setDiscardDialogOpen(false);
                setIsSaved(true);
                onSave(data);
            });
    }, [currentTool, tool, onSave]);

    const handleUrlChange = useCallback((e) => {
        setCurrentTool((prevData) => {
            return { ...prevData, postUrl: e.target?.value }
        });
    }, []);

    const handleTitleChange = useCallback((e) => {
        setCurrentTool((prevData) => {
            return { ...prevData, postTitle: e.target?.value }
        });
    }, []);

    const handlePostContentChange = useCallback((e) => {
        if (currentTool?.postContent !== e) {
            setCurrentTool((prevData) => {
                return { ...prevData, postContent: e }
            });
        }
    }, [currentTool]);

    const handlePostSeoDataChange = useCallback((e) => {
        setCurrentTool((prevData) => {
            const newSeoData = { ...prevData?.seoDetails, ...e };
            return { ...prevData, seoDetails: newSeoData }
        });
    }, []);

    const handleDialogClose = useCallback(() => {
        setDiscardDialogOpen(false);
        // onCancel(false);
    }, []);

    const handleDialogPrimaryAction = useCallback(() => {
        handleEditSave();
        // onCancel(false);
    }, [handleEditSave]);

    const handleDialogSecondaryAction = useCallback(() => {
        // setDiscardDialogOpen(true);
        onCancel(false);
    }, [onCancel]);

    const isContentModified = useMemo(() => {
        let flag = false;
        if (currentTool?.seoDetails) {
            for (const keyGroup in currentTool.seoDetails) {
                for (const field in currentTool.seoDetails[keyGroup]) {
                    if (tool.seoDetails?.[keyGroup]?.[field] !== currentTool.seoDetails[keyGroup]?.[field]) {
                        flag = true;
                        break;
                    }
                }
                if (flag) {
                    break;
                }
            }
        }
        return flag || currentTool?.postTitle !== tool?.postTitle
            || currentTool?.postTitle !== tool?.postTitle
            || currentTool?.postContent !== tool?.postContent
            || currentTool?.postUrl !== tool?.postUrl
            || currentTool?.toolCategoryUrl !== tool?.toolCategoryUrl;
    }, [currentTool, tool]);

    const handleEditCancel̉ = useCallback(() => {
        if (isContentModified) {
            setDiscardDialogOpen(true);
        } else {
            onCancel(false);
        }
    }, [isContentModified, onCancel]);

    return (
        <div className='post-editor snipp-flex-col'>
            <div className='snipp-flex-1 post-editor-container'>
                <div>
                    <h2 className='post-viewer-title'>Edit Post</h2>
                    <label>Title</label>
                    <input type='text' defaultValue={currentTool?.postTitle}
                        className='long' onChange={handleTitleChange} />
                    <br /><br />
                    <div className='snipp-flex-row'>
                        <div className='snipp-flex-1'>
                            <label>Category</label>
                            <input type='text' defaultValue={currentTool?.toolCategoryUrl} onChange={handleUrlChange} />
                        </div>
                        <div className='snipp-flex-1'>
                            <label>Slug</label>
                            <input type='text' defaultValue={currentTool?.postUrl} onChange={handleUrlChange} />
                        </div>
                    </div>
                    <br /><br />
                    <TabBar currentTab={currentActiveTab} tabs={['Content', 'SEO']} onTabClick={handleTabSelection}></TabBar>
                    <div className='right-edit-content'>
                        {currentActiveTab === 0 && <PostContentTab post={currentTool} editMode={true} onContentUpdate={handlePostContentChange} />}
                        {currentActiveTab === 1 && <PostSeoTab post={currentTool} editMode={true} onContentUpdate={handlePostSeoDataChange} />}
                    </div>
                </div>
            </div>
            <div className='post-editor-footer'>
                <div className='saved-now'>
                    {isSaved && <div>Saved just now.</div>}
                </div>
                <div className='snipp-flex-row'>

                    <button onClick={handleEditCancel̉} className='btn secondary'>Close</button>
                    &nbsp;&nbsp;
                    <button disabled={!isContentModified} onClick={handleEditSave} className={'btn ' + (isContentModified ? '' : 'disabled')}>Save</button>

                </div>
            </div>

            <SnippDialog isOpen={discardDialogOpen} title={'Discard changes ?'} onClose={handleDialogClose}
                primaryAction={{ label: 'Save', display: 'ON' }}
                onPrimaryAction={handleDialogPrimaryAction}
                secondaryAction={{ label: 'Discard', display: 'ON' }}
                onSecondaryAction={handleDialogSecondaryAction}>
                Your changes will be lost. Do you want to save ?
            </SnippDialog>
        </div>
    );
}

export default PostEditor;