import React from "react";
import LeftIconNav from "../left-icon-nav/left-icon-nav";
import { Outlet } from "react-router-dom";

const LayoutWithLeftIconNav = (props) => {
    return (
        <>
            <LeftIconNav />
            <Outlet />
        </>
    );
}

export default LayoutWithLeftIconNav;