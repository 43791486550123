import React, { useCallback, useMemo, useState } from "react";
import './login.css';
import { useNavigate } from "react-router-dom";

const LoginPage = (props) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const loginDisabled = useMemo(() => {
        return username === '' || password === '';
    }, [username, password]);

    const handleLoginClick = useCallback(() => {
        setError(null);
        fetch('/login', {
            method: 'POST',
            body: JSON.stringify({
                username, password
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (!data['isValidUser']) {
                    setError(data);
                } else {
                    setError(null);
                    navigate('/dashboard');
                }
            });
    }, [username, password, navigate]);

    const keyDownHandler = useCallback((event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleLoginClick();
        }
    }, [handleLoginClick]);

    return (
        <div className="LoginPage snipp-flex-row">
            <div className="LoginContainer">
                <h1>
                    Login to Snipp-It CMS
                </h1>
                <br />
                <br />
                <label>
                    Username
                </label>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                <br />
                <br />
                <br />
                <label>
                    Password
                </label>
                <input type="password" value={password}
                    onChange={(e) => setPassword(e.target.value)} onKeyDown={keyDownHandler} />
                <br />
                <br />
                {error && <div className="err-color">
                    {error?.message}
                    <br />
                </div>}
                <br />
                <button className="btn" disabled={loginDisabled} onClick={handleLoginClick}>Login</button>
            </div>
        </div>
    );
}

export default LoginPage;
