import React, { Fragment } from "react";
import './post-content-tab.css';
import EditorComponent from "../../common/components/editor/editor";

const PostContentTab = (props) => {
    const { post, editMode, onContentUpdate } = props;

    return (
        <Fragment>
            {!editMode && <>
                <label>Content</label>
                <div className="editor-content-container" dangerouslySetInnerHTML={{ __html: post?.postContent }}></div>
            </>}
            <div>
                {editMode && <EditorComponent content={post.postContent} onContentUpdate={onContentUpdate} />}
                <br /><br /><br />
                <label>Preview</label>
                <div className="editor-preview" dangerouslySetInnerHTML={{ __html: post?.postContent }}>
                </div>
            </div>
        </Fragment>
    );
}

export default PostContentTab;