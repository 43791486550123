import React from 'react';
import './App.css';
import PostList from './post/post-list/post-list';
import { Route, Routes } from 'react-router-dom';
import SettingsPage from './settings/settings';
import DashboardPage from './dashboard/dashboard';
import ContactRequestsPage from './contact-requests/contact-requests';
import LoginPage from './login/login';
import LayoutWithLeftIconNav from './common/components/layout-with-left-icon-nav/layout-with-left-icon-nav';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route element={<LayoutWithLeftIconNav />}>
          <Route path='/dashboard' element={<DashboardPage />} />
          <Route path='/posts' element={<PostList />} />
          <Route path='/contact-requests' element={<ContactRequestsPage />} />
          <Route path='/settings' element={<SettingsPage />} />
        </Route>
      </Routes>

    </div>
  );
}

export default App;
