import React, { useEffect, useState } from "react";
import './left-icon-nav.css';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { AiOutlineDashboard } from 'react-icons/ai';
import { MdOutlineArticle } from 'react-icons/md';
import { FaRegCommentAlt } from 'react-icons/fa';
import { IoSettingsOutline } from 'react-icons/io5';
import { BiLogOut } from 'react-icons/bi';

const LeftIconNav = (props) => {
    const location = useLocation();
    const [currentAtiveIconRoute, setCurrentAtiveIconRoute] = useState('');

    useEffect(() => {
        setCurrentAtiveIconRoute(location.pathname);
    }, [location]);

    return (
        <div className='LeftIconNav snipp-flex-col'>
            <div className="snipp-flex-col">
                <div className="left-nav-icon-item">
                    <NavLink to="/dashboard">
                        <AiOutlineDashboard size={'2em'} />
                    </NavLink>
                </div>
                <div className="left-nav-icon-item">
                    <NavLink to="/posts">
                        <MdOutlineArticle size={'2em'} />
                    </NavLink>
                </div>
                <div className="left-nav-icon-item">
                    <NavLink to="/contact-requests">
                        <FaRegCommentAlt size={'2em'} />
                    </NavLink>
                </div>
                <div className="left-nav-icon-item">
                    <NavLink to="/settings">
                        <IoSettingsOutline size={'2em'} />
                    </NavLink>
                </div>
            </div>
            <div>
                <div className="left-nav-icon-item">
                    <Link to="/">
                        <BiLogOut size={'2em'} />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default LeftIconNav;