import React, { useCallback, useState, useEffect, Fragment, useMemo } from "react";
import './add-edit-constants.css';
import SnippDrawer from "../../common/components/snipp-drawer/snipp-drawer";
import { AiOutlineCheck, AiOutlineClose, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import useDeleteDialog from "../../common/hooks/use-delete-dialog/use-delete-dialog";

const AddEditConstants = (props) => {

    const { isOpen, selectedConstant, onClose, onSave } = props;
    const [newConstantData, setNewConstantData] = useState({
        category: '', subCategory: '', name: '', values: []
    });
    const [addFormVisible, setAddFormVisible] = useState(false);
    const [newColName, setNewColName] = useState('');
    const [tableCols, setTableCols] = useState([]);
    const [newRowData, setNewRowData] = useState({});
    const [editRows, setEditRows] = useState([]);
    const [editRowsData, setEditRowsData] = useState({});
    const [colToDelete, setColToDelete] = useState('');
    const [showDeleteDialog, hideDeleteDialog, deleteDialogRendered, deleteDialogResult] = useDeleteDialog();

    const handleDrawerClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleDrawerPrimaryAction = useCallback(() => {
        fetch("/constants" + (newConstantData.id ? "/" + newConstantData?.id : ""), {
            method: newConstantData.id ? 'PUT' : "POST",
            body: JSON.stringify(newConstantData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                onSave(data);
            });
    }, [newConstantData, onSave]);

    const handleDrawerSecondaryAction = useCallback(() => {
        handleDrawerClose();
    }, [handleDrawerClose]);

    useEffect(() => {
        if (selectedConstant) {
            if (selectedConstant?.values?.[0]) {
                setTableCols(Object.keys(selectedConstant.values[0]));
            }
            setNewConstantData(JSON.parse(JSON.stringify(selectedConstant)));
        }
    }, [selectedConstant]);

    useEffect(() => {
        if (!isOpen) {
            setTableCols([]);
        }
    }, [isOpen]);

    const handleItemDelete = useCallback((v, i) => {
        newConstantData.values.splice(i, 1);
        setNewConstantData(JSON.parse(JSON.stringify(newConstantData)));
        if (editRows.includes(i)) {
            editRows.splice(editRows.indexOf(i), 1);
            setEditRows([...editRows]);
        }
    }, [newConstantData, editRows]);

    const handleColumnDelete = useCallback((key) => {
        showDeleteDialog();
        setColToDelete(key);
    }, [showDeleteDialog]);


    const deleteColumn = useCallback(() => {
        newConstantData.values?.forEach((c) => {
            delete c[colToDelete];
        });
        newConstantData.values = newConstantData.values?.filter(o => Object.keys(o)?.length > 0);
        setNewConstantData(JSON.parse(JSON.stringify(newConstantData)));
        if (tableCols.includes(colToDelete)) {
            tableCols.splice(tableCols.indexOf(colToDelete), 1);
            setTableCols([...tableCols]);
        }
    }, [newConstantData, tableCols, colToDelete]);

    useEffect(() => {
        if (deleteDialogResult === 'DELETE' && colToDelete) {
            deleteColumn();
            hideDeleteDialog();
            setColToDelete('');
        }
    }, [deleteColumn, deleteDialogResult, hideDeleteDialog, colToDelete]);

    const handleItemEditStart = useCallback((v, i) => {
        if (!editRows.includes(i)) {
            editRows.push(i);
            setEditRows([...editRows]);
            editRowsData[i] = {};
            tableCols.forEach(key => {
                editRowsData[i][key] = v[key];
            });
            setEditRowsData(editRowsData);
        }
    }, [editRows, editRowsData, tableCols]);

    const handleEditCancel = useCallback((i) => {
        if (isNaN(i)) {
            setAddFormVisible(false);
            setNewRowData({});
        } else {
            if (editRows.includes(i)) {
                editRows.splice(editRows.indexOf(i), 1);
                setEditRows([...editRows]);
            }
            newConstantData.values.splice(i, 1, newConstantData.values[i]);
            setNewConstantData(JSON.parse(JSON.stringify(newConstantData)));
        }
    }, [editRows, newConstantData]);

    const handleEditConfirm = useCallback((index) => {
        if (isNaN(index)) {
            if (!newConstantData.values) {
                newConstantData.values = [];
            }
            newConstantData?.values?.push(newRowData);
            setNewConstantData(JSON.parse(JSON.stringify(newConstantData)));
            handleEditCancel();
        } else {
            if (editRows.includes(index)) {
                editRows.splice(editRows.indexOf(index), 1);
                setEditRows([...editRows]);
            }
            newConstantData.values.splice(index, 1, editRowsData[index]);
            setNewConstantData(JSON.parse(JSON.stringify(newConstantData)));
        }
    }, [newRowData, newConstantData, handleEditCancel, editRows, editRowsData]);

    const handleNewRowDataChange = useCallback((e, t) => {
        newRowData[t] = e.target.value;
        setNewRowData(JSON.parse(JSON.stringify(newRowData)));
    }, [newRowData]);

    const handleExistingRowDataChange = useCallback((e, key, rowIndex) => {
        editRowsData[rowIndex][key] = e.target.value;
        setEditRowsData(editRowsData);
    }, [editRowsData]);

    const handleAddRow = useCallback(() => {
        setAddFormVisible(true);
    }, []);

    const handleConstFieldsTextChange = useCallback((e, key) => {
        newConstantData[key] = e.target.value;
        setNewConstantData(JSON.parse(JSON.stringify(newConstantData)));
    }, [newConstantData]);

    const handleNewColNameChange = useCallback((e) => {
        setNewColName(e.target.value);
    }, []);

    const handleAddNewCol = useCallback(() => {
        tableCols.push(newColName);
        setTableCols([...tableCols]);
        newConstantData?.values?.forEach(v => {
            v[newColName] = ''
        });
        setNewConstantData(JSON.parse(JSON.stringify(newConstantData)));
        setNewColName('');
    }, [tableCols, newColName, newConstantData]);

    const handleNewColNameKeyup = useCallback((e) => {
        if (e.key === 'Enter' && newColName) {
            handleAddNewCol();
        }
    }, [handleAddNewCol, newColName]);

    const isContentModified = useMemo(() => {
        let flag = false;
        newConstantData?.values?.every((n, i) => {
            let keys = Object.keys(n);
            keys.every(k => {
                if (n[k] !== selectedConstant?.values?.[i]?.[k]) {
                    flag = true;
                    if (flag) {
                        return false;
                    }
                }
                return true;
            });
            if (flag) {
                return false;
            }
            return true;
        });

        return flag || newConstantData?.name !== selectedConstant?.name ||
            newConstantData?.category !== selectedConstant?.category ||
            newConstantData?.subCategory !== selectedConstant?.subCategory ||
            newConstantData?.values?.length !== selectedConstant?.values?.length;
    }, [newConstantData, selectedConstant]);

    return (
        <SnippDrawer open={isOpen} title={'Add/Edit Constants'}
            onClose={handleDrawerClose} width={'60%'}
            primaryAction={{ label: 'Save', display: isContentModified ? 'ON' : 'DISABLED' }}
            onPrimaryAction={handleDrawerPrimaryAction}
            secondaryAction={{ label: 'Cancel', display: 'ON' }}
            onSecondaryAction={handleDrawerSecondaryAction}
        >
            <div className="mar-bot-20">
                <label>Title</label>
                <input type='text' defaultValue={newConstantData?.name} onChange={(e) => handleConstFieldsTextChange(e, 'name')} />
            </div>
            <div className='snipp-flex-row'>
                <div className="mar-bot-20 snipp-flex-1">
                    <label>Category</label>
                    <input type='text' defaultValue={newConstantData?.category} onChange={(e) => handleConstFieldsTextChange(e, 'category')} />
                </div>
                <div className="mar-bot-20 snipp-flex-1">
                    <label>Sub Category</label>
                    <input type='text' defaultValue={newConstantData?.subCategory} onChange={(e) => handleConstFieldsTextChange(e, 'subCategory')} />
                </div>
            </div>
            <div className="mar-bot-20">
                <label>Values</label>
                <table width="100%" cellPadding={0} cellSpacing={0} border={0} className="settings-const-table">
                    <thead className="sticky-table-head">
                        <tr>
                            {tableCols?.map((t, i) => {
                                return (
                                    <th key={i}>
                                        {t}
                                        &nbsp;&nbsp;&nbsp;
                                        {!addFormVisible && !newColName && <AiOutlineDelete title="Delete" className="snipp-flex-1" onClick={() => handleColumnDelete(t)} />}
                                    </th>
                                );
                            })}
                            {tableCols?.length > 0 && <th className="max-width-90 text-center">Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            newConstantData.values?.map((v, i) => {
                                return (
                                    <tr key={i} onDoubleClick={() => handleItemEditStart(v, i)}>
                                        {tableCols?.length > 0 && tableCols?.map((t, j) => {
                                            return (
                                                editRows.includes(i) ?
                                                    <td key={j}>
                                                        <input type="text" className="inline" defaultValue={v[t]}
                                                            onChange={(e) => handleExistingRowDataChange(e, t, i)} />
                                                    </td>
                                                    :
                                                    <td key={j}>{v[t]}</td>
                                            );
                                        })}
                                        {tableCols?.length > 0 && <td className="snipp-flex-row max-width-90">

                                            {!editRows.includes(i) ? <Fragment>
                                                <AiOutlineEdit title="Edit" className="snipp-flex-1" onClick={() => handleItemEditStart(v, i)} />
                                                <AiOutlineDelete title="Delete" className="snipp-flex-1" onClick={() => handleItemDelete(v, i)} />
                                            </Fragment> :
                                                <Fragment>
                                                    <AiOutlineCheck title="Confirm" className="snipp-flex-1" onClick={() => handleEditConfirm(i)} />
                                                    <AiOutlineClose title="Cancel" className="snipp-flex-1" onClick={() => handleEditCancel(i)} />
                                                </Fragment>
                                            }
                                        </td>
                                        }
                                    </tr>
                                );
                            })
                        }
                        {tableCols?.length > 0 && addFormVisible &&
                            <tr>
                                {tableCols?.map((t, j) => {
                                    return (
                                        <td key={j}>
                                            <input type="text" className="inline" defaultValue={newRowData[t]}
                                                onChange={(e) => handleNewRowDataChange(e, t)} />
                                        </td>
                                    );
                                })}
                                <td className="snipp-flex-row max-width-90">
                                    <AiOutlineCheck title="Confirm" className="snipp-flex-1" onClick={handleEditConfirm} />
                                    <AiOutlineClose title="Cancel" className="snipp-flex-1" onClick={handleEditCancel} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {tableCols?.length === 0 && !addFormVisible && <div className="text-center"><br /><br />No data present.</div>}
                <br /><br />
                <div className="snipp-flex-row add-constant-option">
                    <button className="btn snipp-flex-1"
                        disabled={addFormVisible || newColName || editRows?.length > 0 || tableCols?.length === 0}
                        onClick={handleAddRow}>Add New Row</button>
                    <div className="snipp-flex-1 text-center">OR</div>
                    <div className="snipp-flex-row snipp-flex-3">
                        <input type="text" disabled={addFormVisible}
                            value={newColName}
                            className="inline snipp-flex-1" onChange={handleNewColNameChange} onKeyUp={handleNewColNameKeyup} />
                        &nbsp;&nbsp;&nbsp;
                        <button className="btn snipp-flex-1" disabled={addFormVisible || !newColName} onClick={handleAddNewCol}>Add New Column</button>
                    </div>
                </div>
            </div>
            {deleteDialogRendered}
        </SnippDrawer>
    );
}

export default AddEditConstants;