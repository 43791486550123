import React, { useCallback, useMemo, useState } from "react";
import './use-delete-dialog.css';
import SnippDialog from "../../components/snipp-dialog/snipp-dialog";

const useDeleteDialog = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    const [dialogResult, setDialogResult] = useState('');

    const showDeleteDialog = useCallback(() => {
        setIsOpen(true);
        setDialogResult(false);
    }, []);

    const hideDeleteDialog = useCallback(() => {
        setIsOpen(false);
        setDialogResult('CANCEL');
    }, []);

    const handleDialogPrimaryAction = useCallback(() => {
        setIsOpen(false);
        setDialogResult('DELETE');
    }, []);

    const deleteDialogRendered = useMemo(() => {
        return <SnippDialog title={'Confirm Delete ?'}
            isOpen={isOpen}
            primaryAction={{ label: 'Delete', display: 'ON' }}
            onPrimaryAction={handleDialogPrimaryAction}
            secondaryAction={{ label: 'Cancel', display: 'ON' }}
            onSecondaryAction={hideDeleteDialog}
        >
            Are you sure you want to delete ? You will not be able to recover these changes.
        </SnippDialog>;
    }, [isOpen, handleDialogPrimaryAction, hideDeleteDialog]);

    return [showDeleteDialog, hideDeleteDialog, deleteDialogRendered, dialogResult];
}

export default useDeleteDialog;